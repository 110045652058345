const getInlenersCaoTemplate = (bodyHtml: string, imageSrc: string) => {
return `
<html>
  <head>
    <style>
      body {
        font-size: 19px;
        font-family: 'Open sans', sans-serif;
      }
      .company-logo {
        width: 100%;
        height: 80px;
      }
      .company-logo img {
        height: auto;
        max-width: 240px;
        max-height: 240px;
        float: right;
      }
      table td {
        border: 1px solid black;
      }
      table th {
        border: 1px solid black;
      }
      table tr:first-child th:first-child {
        border: none;
      }
      table {
        width: 100%;
        border-spacing: 0px !important;
        border-collapse: collapse !important;
        text-align: center;
      }
      .table-wrapper {
        position: relative;
        overflow-x: auto;
      }
    </style>
  </head>
  <body>
    <div class="company-logo">
    <img src="${ imageSrc }" />
    </div>
    <br />
    ${ bodyHtml }
  </body>
</html>
`;
};

export default getInlenersCaoTemplate;
